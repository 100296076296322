export enum Origin {
  PHONE = 'phone',
  ONLINE = 'online',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  MICHELIN = 'michelin',
  TRIPADVISOR = 'tripadvisor',
  PORTAL = 'portal',
  PORTAL_SEARCH = 'portal_search',
}

export enum Status {
  PENDING = 'pending',
  NO_SHOW = 'no_show',
  CANCELLED = 'cancelled',
  CONFIRMED = 'confirmed',
  SEATED = 'seated',
  BEING_LATE = 'being_late',
  PARTLY_SEATED = 'partly_seated',
  WALK_IN = 'walk_in',
  TEMPLATE = 'template',
  WAITING_LIST = 'waiting_list',
  BLOCKED = 'blocked',
  PRE_BOOKED = 'pre_booked',
  NONE = 'none',
}

export enum SubStatus {
  WALK_IN = 'walk_in',
  WITH_NAME = 'with_name',
  WITHOUT_NAME = 'without_name',
  REPEAT = 'repeat',
  RESERVATION = 'reservation',
  ONE_TIME = 'one_time',
  PRIVATE_EVENT = 'private_event',
  BUSINESS_EVENT = 'business_event',
  WEDDING = 'wedding',
  CATERING = 'catering',
  NONE = 'none',
}

export enum Type {
  BOOKING = 'booking',
  WALK_IN = 'walk_in',
  EVENT = 'event',
  BLOCKED = 'blocked',
  WAITING_LIST = 'waiting_list',
  TEMPLATE = 'template',
}

export enum ReservationAdditionalInfo {
  BIRTHDAY = 107,
  ANNIVERSARY = 100,
  WITH_KIDS = 101,
  HIGH_CHAIR = 102,
  GIFT_VOUCHER = 103,
  WITH_PET = 104,
  WHEELCHAIR_ACCESS = 105,
  IMPORTANT = 106,
}

export enum ReservationDietaryRequirements {
  LACTOSE = 200,
  GLUTEN = 201,
  DIARY_FREE = 202,
  EGGS = 203,
  SOY = 204,
  NUTS = 205,
  FISH_SHELLFISH = 206,
  VEGETARIAN = 208,
  VEGAN = 209,
  PESCETARIAN = 210,
  KOSHER = 211,
}
